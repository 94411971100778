import React, { useState, useEffect } from 'react';
import { fetchAttendanceData, fetchEmployees, submitAttendanceData } from './Services/attendanceService';
import { getCompanyInformation } from './Services/settingService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";


const AttendanceForm = ({ empCode, date, onCancel }) => {
    const navigate = useNavigate();
    
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

    const [formData, setFormData] = useState({
        year: currentYear,
        month: currentMonth,
        working_Days: 0,
    });
    const [employees, setEmployees] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ ,setIsPayrollGenerated] = useState(false);
    const [companyId, setCompanyId] = useState(null); 

    // Generate year options (2020 to 2030)
   

    // List of months
    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ];
    useEffect(() => {
        const getCompanyId = async () => {
            try {
                const companyInfo = await getCompanyInformation();
                if (companyInfo.length > 0) {
                    setCompanyId(companyInfo[0].Id); 
                }
            } catch (error) {
                console.error('Error fetching company ID:', error);
            }
        };

        getCompanyId(); 
    }, []);

    useEffect(() => {

        const getEmployees = async () => {
            try {
                
                const response = await fetchEmployees();
                
                if (response && response.length > 0) {

                    const initializedEmployees = response.map(employee => ({
                        emp_code: employee.emp_code,
                        name: employee.name,
                        designation: employee.designation,
                        PL: 0,
                        SL: 0,
                        LWP: 0,
                        present_Days: 0,
                        empId:employee.Id,
                        working_Days: formData.working_Days // Initialize with top working days
                    }));
                    
                    
                    setEmployees(initializedEmployees); // Set employees to state
                } 
            } catch (error) {
               toast.error('Error fetching employees:', error);
            }
        };
    
        getEmployees(); // Fetch employees when component mounts
    }, []);

    useEffect(() => {
        const getAttendanceData = async () => {
            if (empCode && date) {
                try {
                    const response = await fetchAttendanceData(empCode, date);
                    setFormData(response);
                    const updatedRows = employees.map(employee => ({
                        ...employee,
                        ...response.employees.find(row => row.emp_code === employee.emp_code),
                    }));
                    setEmployees(updatedRows);
                } catch (error) {
                    console.error('Error fetching attendance data:', error);
                }
            }
        };

        getAttendanceData();
    }, [empCode, date, employees]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => {
            const updatedData = { ...prevData, [name]: value };

            // If the working_Days field is changed, update all employees' working days
            if (name === 'working_Days') {
                setEmployees(prevEmployees => prevEmployees.map(emp => ({
                    ...emp,
                    working_Days: parseFloat(value), // Update employee's working days
                    present_Days: parseFloat(value) - (emp.PL + emp.SL + emp.LWP) // Recalculate present days
                })));
            }
            return updatedData;
        });
    };

    const handleRowChange = (index, e) => {
        const { name, value } = e.target;
        const updatedRows = [...employees];
        updatedRows[index] = { ...updatedRows[index], [name]: value };

        const { working_Days } = formData;
        const row_working_Days=parseFloat(updatedRows[index].working_Days) || working_Days;
        const PL = parseFloat(updatedRows[index].PL) || 0;
        const SL = parseFloat(updatedRows[index].SL) || 0;
        const LWP = parseFloat(updatedRows[index].LWP) || 0;

        // Recalculate present days for the employee based on leaves and working days
        updatedRows[index].present_Days = row_working_Days - (PL + SL + LWP);
        
        setEmployees(updatedRows);
        
    };

    const handlePresentDayChange = (index, e) => {
        const { value } = e.target;
        const updatedRows = [...employees];
        updatedRows[index].present_Days = parseFloat(value);
        setEmployees(updatedRows);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        
        if (!formData.year || !formData.month) {
        toast.error('Year and month are required');
            setIsSubmitting(false);
            return;
        }
        if (!companyId) {
            toast.error('Company ID is required');
            setIsSubmitting(false);
            return;
        }

        if (employees.some(row => !row.emp_code)) {
            toast.error('All rows must have an employee code');
            setIsSubmitting(false);
            return;
        }

        try {
            const dataToSubmit = { ...formData, employees,companyId };
            const response = await submitAttendanceData(empCode, date, dataToSubmit);


            if (response.message?.includes('Payroll already generated')) {
                toast.success('Payroll for this month and year has already been generated.');
                setIsPayrollGenerated(true);
            } else {
                toast.success('Attendance entry successfully submitted.');
                navigate('/payroll')
            }
        } catch (error) {
            console.error('Error submitting attendance:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCancel = () => {
        setFormData({ year: '', month: '', working_Days: 0 });
        
        setEmployees(prevEmployees => prevEmployees.map(employee => ({
            ...employee,
            working_Days: 0,  // Reset working days for each employee
            PL: 0,
            SL: 0,
            LWP: 0,
            present_Days: 0,
        })));
        
        if (onCancel && typeof onCancel === 'function') {
            onCancel();
        }
    };

    return (
        <div className="max-w-[90%] mx-auto p-4 m-4 border border-gray-200 rounded-lg shadow-md bg-white">
            <h2 className="text-2xl font-semibold mb-4">Attendance Entry</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Year</label>
                    <input
                        type="text"
                        name="year"
                        placeholder="Enter Year (e.g., 2024)"
                        value={formData.year}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,4}$/.test(value)) {
                                setFormData(prevData => ({
                                    ...prevData,
                                    year: value,
                                }));
                            }
                        }}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Month</label>
                    <select
                        name="month"
                        value={formData.month}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required
                    >
                        <option value="">Select Month</option>
                        {months.map(month => (
                            <option key={month.value} value={month.value}>{month.label}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Working Days</label>
                    <input
                        type="number"
                        name="working_Days"
                        value={formData.working_Days}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
    
            <div className="mb-4 overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                    <thead>
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">S.No</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Employee Code</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Designation</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Working Days</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">PL</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SL</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">LWP</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Present Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map((employee, index) => (
                            <tr key={employee.emp_code}>
                                <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{employee.emp_code}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{employee.name}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{employee.designation}</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="number"
                                        name="working_Days"
                                        value={employee.working_Days}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="number"
                                        name="PL"
                                        value={employee.PL}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="number"
                                        name="SL"
                                        value={employee.SL}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="number"
                                        name="LWP"
                                        value={employee.LWP}
                                        onChange={(e) => handleRowChange(index, e)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <input
                                        type="number"
                                        name="present_Days"
                                        value={employee.present_Days}
                                        onChange={(e) => handlePresentDayChange(index, e)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
    
            <div className="flex flex-col sm:flex-row justify-start space-x-0 sm:space-x-4 mt-4">
                <button
                    type="button"
                    onClick={handleSubmit}
                    className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    {isSubmitting ? 'Submitting...' : 'Save'}
                </button>
                <button
                    type="button"
                    onClick={handleCancel}
                    className="w-full sm:w-auto mt-2 sm:mt-0 px-4 py-2 bg-gray-600 text-white font-semibold rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                    Cancel
                </button>
            </div>
        
<ToastContainer />
        </div>
    );
};

export default AttendanceForm;
